<template>
	<v-row justify="center">
		<v-dialog v-model="showdialog" persistent max-width="800px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Deliverable</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-text-field label="Name" outlined v-model="Deliverable.Name"></v-text-field>
						</v-row>
						<v-row>
							<v-text-field label="Link" outlined v-model="Deliverable.Link"></v-text-field>
						</v-row>
						<v-row>
							<v-select label="Type" outlined v-model="Deliverable.Type" :items="['url', 'download']"></v-select>
						</v-row>
						<v-row>
							<v-select label="Target" outlined v-model="Deliverable.Target" :items="['_blank', '_self']"></v-select>
						</v-row>
						<v-row>
							<v-alert outlined type="error" dense v-if="errorUpdateMessage != ''">
								{{ errorUpdateMessage }}
							</v-alert>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="CloseDialog">
						Close
					</v-btn>
					<v-btn color="blue darken-1" text @click="Save">
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			ProductVersion: {},
			Deliverable: {},
			errorUpdateMessage: "",
		};
	},
	watch: {
		showdialog : function(){
			const _this = this;
			_this.errorUpdateMessage = "";
			_this.ProductVersion = _this.InitialData;
			if (_this.DeliverableIndex != null) _this.Deliverable = _this.ProductVersion?.Documents[_this.DeliverableIndex];
			else _this.Deliverable = {};
		},
		
	},
	props: ["showdialog", "InitialData", "ParentId", "DeliverableIndex"],
	methods: {
		CloseDialog() {
			this.$emit("update:dialog", false);
		},
		Save() {
			const _this = this;
			if (_this.DeliverableIndex == null) {
				if (!_this.ProductVersion.Documents) {
					_this.ProductVersion.Documents = [];
				}
				_this.ProductVersion.Documents.push(_this.Deliverable);
			} else {
				_this.ProductVersion.Documents[_this.DeliverableIndex] = _this.Deliverable;
			}
			this.$store
				.dispatch(`products/updateRelated`, { name: "ProductVersion", item: _this.ProductVersion })
				.then(() => {
					_this.errorUpdateMessage = "";
					_this.$emit("update:dialog", false);
				})
				.catch((error) => {
					_this.errorUpdateMessage = error.response.data.details ? error.response.data.details.map((x) => x.message).join("</br>") : error.response.data.message;
				});
		},
	},
};
</script>

<style scoped>
div {
	text-align: left;
}
</style>
