<template>
	<v-row justify="center">
		<v-dialog v-model="showdialog" persistent max-width="800px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Product Version</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-text-field label="Title" outlined v-model="ProductVersion.Name"></v-text-field>
						</v-row>
						<v-row>
							<v-textarea label="Content" outlined v-model="ProductVersion.Description"></v-textarea>
						</v-row>
						<v-row>
							<v-alert outlined type="error" dense v-if="errorUpdateMessage != ''">
								{{ errorUpdateMessage }}
							</v-alert>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="CloseDialog">
						Close
					</v-btn>
					<v-btn color="blue darken-1" text @click="Save">
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			ProductVersion: {},
			errorUpdateMessage: "",
		};
	},
	watch: {
		showdialog: function() {
			const _this = this;
			_this.ProductVersion = _this.InitialData;
		},
	},
	props: ["showdialog", "InitialData", "ParentId"],
	methods: {
		CloseDialog() {
			this.$emit("update:dialog", false);
		},
		Save() {
			const _this = this;
			if (_this.ProductVersion.Id) {
				_this.$store
					.dispatch(`products/updateRelated`, { name: "ProductVersion", item: _this.ProductVersion })
					.then(() => {
						_this.ProductVersion = {};
						_this.CreateNew = false;
						_this.errorUpdateMessage = "";
						_this.$emit("update:dialog", false);
					})
					.catch((error) => {
						_this.errorUpdateMessage = error.response.data.details.map((x) => x.message).join("</br>");
					});
			} else {
				_this.$store
					.dispatch(`products/addRelated`, { id:_this.ParentId,  name: "ProductVersion", item: _this.ProductVersion })
					.then(() => {
						_this.ProductVersion = {};
						_this.CreateNew = false;
						_this.errorUpdateMessage = "";
						_this.$emit("update:dialog", false);
					})
					.catch((error) => {
						_this.errorUpdateMessage = error.response.data.details.map((x) => x.message).join("</br>");
					});
			}
		},
	},
};
</script>

<style scoped>
div {
	text-align: left;
}
</style>
