import Vue from "vue";

/**
 * Store.Base.Mutations
 *
 * Store mutations : Synchronous calls that modifies the state of the store
 */
export default {
	/**
	 * Adds all given items in store, indexed by Id property
	 * @param { [ Object ] } items Items to be added in store
	 */
	setCollection: (state, items) => {
		for (let item of items) {
			if (state.all[item.Id]) {
				state.all[item.Id] = {
					...state.all[item.Id],
					...item,
				};
			} else Vue.set(state.all, item.Id, item);
		}
	},

	/**
	 * Adds the given items in store, indexed by Id property
	 * @param { Object } item Item to be added in store
	 */
	setItem: (state, item) => {
		if (state.all[item.Id]) {
			state.all[item.Id] = {
				...state.all[item.Id],
				...item,
			};
		} else Vue.set(state.all, item.Id, item);
	},

	/**
	 * Remove the given items from store
	 * @param { String } id Id of the item to remove
	 */
	removeItem: (state, id) => {
		Vue.delete(state.all, id);
	},

	/**
	 * Save a promise in store for the request that loads all the data
	 * @param { Promise } promise The request promise
	 */
	setPromise: (state, promise) => {
		Vue.set(state.promises, "---ALL---", promise);
	},

	/**
	 * Save a promise in store by key
	 * @param { String } id The key to save/get the promise
	 * @param { Promise } promise The request promise
	 */
	setPromiseById: (state, { id, promise }) => {
		Vue.set(state.promises, id, promise);
	},

	/**
	 * Remove from store the promise of the request that loads all the data
	 */
	clearPromise: (state) => {
		Vue.delete(state.promises, "---ALL---");
	},

	/**
	 * Remove a promise from store by key
	 * @param { String } id The key of the promise
	 */
	clearPromiseById: (state, id) => {
		Vue.delete(state.promises, id);
	},

	/**
	 * Adds all given related items in store for a relationship, attached to the source item found in store, indexed by Id property
	 * @param { String } id Source item Id
	 * @param { String } name Relationship name
	 * @param { [ Object ] } items Items to be added in store
	 */
	setRelationship: (state, { id, name, items }) => {
		if (name == "ProductVersion") {
			items = items.map((x) => {
				if (!x.Documents) x.Documents = [];
				return x;
			});
		}
		const current = state.all[id];
		if (!current) return;

		if (!current[name]) Vue.set(state.all[id], name, {});

		for (let item of items) {
			if (current[name][item.Id]) {
				state.all[id][name][item.Id] = {
					...state.all[id][name][item.Id],
					...item,
				};
			} else Vue.set(state.all[id][name], item.Id, item);
		}
	},

	/**
	 * Adds one related item in store for a relationship, attached to the source item found in store, indexed by Id property
	 * @param { String } id Source item Id
	 * @param { String } name Relationship name
	 * @param { [ Object ] } item Item to be added in store
	 */
	setRelated: (state, { id, name, item }) => {
		if (name == "ProductVersion") {
			if (!item.Documents) item.Documents = [];
		}
		const current = state.all[id];
		if (!current) return;
		if (!current[name]) Vue.set(state.all[id], name, {});
		Vue.set(state.all[id][name], item.Id, item);
	},
	/**
	 * REmovers item in store for a relationship
	 * @param { String } id Source item Id
	 * @param { String } name of the relationship
	 * @param {String} related_id Item to be removed from the store
	 */
	removeRelated: (state, { id, name, related_id }) => {
		/*const current = state.all[id];
		if (!current) return;
		let property = current[name];
		if (property[related_id]) {
			delete property[related_id];
		}
    
		Vue.set(state.all, id, current)*/
    let copystateall = state.all;
    delete  copystateall[id][name][related_id]
    state.all = copystateall


	},
};
