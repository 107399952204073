<template>
	<v-container>
		<v-snackbar v-model="snackbar" :timeout="1000" color="success">{{ SnackBarInfo }}</v-snackbar>
		<v-form ref="form">
			<v-row>
				<v-col md="4">
					<h2>Account</h2>
					<v-avatar size="100" color="primary">
						<v-icon dark v-if="!currentUser.pictureurl">
							mdi-account-circle
						</v-icon>
						<v-img v-if="currentUser.pictureurl" :src="currentUser.pictureurl"> </v-img>
					</v-avatar>
				</v-col>
				<v-col md="4">
					<v-text-field v-model="currentUser.Username" regular label="Username" readonly></v-text-field>
					<v-file-input prepend-icon="" :rules="[rules.avatar]" @change="UploadUserAvatar" accept="image/png, image/jpeg, image/bmp" placeholder="Pick an avatar" append-icon="mdi-camera" label="Avatar"></v-file-input>
				</v-col>
			</v-row>
			<v-row>
				<v-col md="4">
					<h2>Main Settings</h2>
				</v-col>
				<v-col md="4">
					<v-text-field
						v-model="currentUser.FullName"
						regular
						label="Full Name"
						clearable
						required
						:rules="[rules.required]"
						@input="
							(e) => {
								name = e;
							}
						"
					></v-text-field>
					<v-text-field
						v-model="currentUser.Email"
						regular
						label="Email"
						clearable
						required
						:rules="[rules.email]"
						@input="
							(e) => {
								email = e;
							}
						"
					></v-text-field>
					<v-text-field
						v-model="currentUser.PhoneNumber"
						regular
						color="blue-grey lighten-2"
						label="Phone Number"
						clearable
						required
						@input="
							(e) => {
								phone = e;
							}
						"
					></v-text-field>

					<v-switch @change="SetDarkTheme(!isDarkTheme)" label="Use Dark Theme" :input-value="isDarkTheme"></v-switch>
				</v-col>
			</v-row>
			<v-row>
				<v-col md="4"> </v-col>
				<v-col md="4"><v-btn class="float-left" color="primary" outlined @click="Save">Save</v-btn> </v-col>
			</v-row>
			<v-row class="d-flex justify-center">
				<v-alert outlined type="error" dense v-if="errorUpdateMessage != ''">
					{{ errorUpdateMessage }}
				</v-alert>
			</v-row>
			<v-row>
				<v-col md="4">
					<h2>Password</h2>
				</v-col>
				<v-col md="4">
					<v-text-field v-model="oldPassword" autocomplete="current-password" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.min]" :type="show2 ? 'text' : 'password'" name="password" label="Current Password" class="input-group--focused" @click:append="show2 = !show2"></v-text-field>
					<v-text-field v-model="newPassword" autocomplete="new-password" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.min]" :type="show2 ? 'text' : 'password'" name="password" label="New Password" class="input-group--focused" @click:append="show2 = !show2"></v-text-field>
					<v-text-field
						autocomplete="new-password"
						v-model="confirmPassword"
						:append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
						:rules="[rules.min, rules.confirmPassword]"
						:type="show2 ? 'text' : 'password'"
						name="confirm_password"
						label="Confirm Password"
						class="input-group--focused"
						@click:append="show2 = !show2"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row class="d-flex justify-center">
				<v-alert outlined type="error" dense v-if="errorPasswordMessage != ''">
					{{ errorPasswordMessage }}
				</v-alert>
			</v-row>

			<v-row>
				<v-col md="4"> </v-col>
				<v-col md="4">
					<v-progress-circular indeterminate color="primary" v-if="Updating"></v-progress-circular>
					<v-btn class="float-left" color="primary" outlined v-if="!Updating" @click="ChangePassword">Change Password</v-btn>
				</v-col>
			</v-row>

			<v-row> </v-row>
		</v-form>
	</v-container>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
	data() {
		return {
			Updating: false,
			SnackBarInfo: "Updated !",
			errorPasswordMessage: "",
			errorUpdateMessage: "",
			snackbar: false,
			newPassword: "",
			confirmPassword: "",
			oldPassword: "",
			name: "",
			email: "",
			phone: "",
			show2: false,
			rules: {
				required: (value) => !!value || "Required.",
				min: (v) => (v && v.length >= 6) || "Min 8 characters",
				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || "Invalid e-mail.";
				},
				confirmPassword: (v) => this.newPassword == v || "Password doesn't match",
				avatar: (value) => !value || value.size < 500000 || "Avatar size should be less than 500 KB!",
			},
		};
	},
	computed: {
		...mapGetters("application", ["isDarkTheme"]),
		...mapState({
			currentUser: (state) => state.profile.user,
		}),
	},
	methods: {
		...mapMutations("application", ["SetDarkTheme"]),
		ChangePassword() {
			const _this = this;
			_this.Updating = true;
			if (this.oldPassword && this.newPassword && this.confirmPassword && this.newPassword == this.confirmPassword) {
				this.$store
					.dispatch("profile/ChangePassword", { oldPassword: this.oldPassword, newPassword: this.newPassword, confirmPassword: this.confirmPassword })
					.then(() => {
						_this.errorPasswordMessage = "";
						_this.SnackBarInfo = "Password Succesfully Changed";
						_this.newPassword = "";
						_this.confirmPassword = "";
						_this.oldPassword = "";
						_this.$refs.form.resetValidation();
						_this.snackbar = true;
						_this.Updating = false;
					})
					.catch(() => {
						_this.errorPasswordMessage ="Password change failed, please contact support";
						_this.Updating = false;
					});
			} else if (this.confirmPassword != this.newPassword) {
				_this.errorPasswordMessage = "Passwords doesn't match.";
				_this.Updating = false;
			} else {
				_this.errorPasswordMessage = "Incorrect combination of passwords.";
				_this.Updating = false;
			}
		},
		Save() {
			const _this = this;
			let Profile = {};
			if (this.email) Profile.Email = this.email;
			if (this.name) Profile.FullName = this.name;
			if (this.phone) Profile.PhoneNumber = this.phone;
			this.$store
				.dispatch("profile/updateUser", Profile)
				.then(function() {
					_this.errorUpdateMessage = "";
					_this.SnackBarInfo = "Profile updated";
					_this.snackbar = true;
				})
				.catch((x) => (_this.errorUpdateMessage = x.message));
		},
		UploadUserAvatar(File) {
			const _this = this;
			this.$store.dispatch("profile/UploadAvatar", File).then(function() {
				_this.SnackBarInfo = "Profile picture updated";
				_this.snackbar = true;
			});
		},
	},
	beforeMount() {
		this.name = "" || this.currentUser.family_name;
		this.email = "" || this.currentUser.email;
		this.phone = "" || this.currentUser.phone_number;
	},
};
</script>

<style>
.v-input--is-readonly .v-input__control .v-input__slot:before {
	border-style: none !important;
}
</style>
