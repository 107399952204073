/**
 * Store.Base.Getters
 * 
 * Store getters : Provide access to the data of a namespaced module in store
 */
 export default {

  /**
   * Get the full list of items in module's store
   * @returns { [ Object ] || [] }
   */
  get: (state) => {
    return Object.values(state.all)
  },
  
  /**
   * Get one item from the store by Id
   * @param { String } id Item Id
   * @returns { Object || undefined }
   */
  getById: (state) => (id) => {
    return state.all[id]
  },

  /**
   * Get the full list of related items by relationship name and source id
   * @param { String } id The source Id (parent item Id)
   * @param { String } name The relationship name
   * @returns { [ Object ] || [] }
   */
  getRelationship: (state, getters) => (id, name) => {
    let item = getters.getById(id)
    return item && item[name] && Object.values(item[name])
  },

  /**
   * Get one related item by relationship name, source id and id
   * @param { String } id The source Id (parent item Id)
   * @param { String } name The relationship name
   * @param { String } relatedId The related item Id
   * @returns { Object || undefined }
   */
  getRelated: (state, getters) => (id, name, relatedId) => {
    let item = getters.getById(id)
    return item && item[name] && item[name][relatedId]
  },

  /**
   * Get the promise for the request that list all of the items for module
   * @returns  { Promise || undefined }
   */
  promise: (state) => {
    return state.promises['---ALL---']
  },

  /**
   * Get the promise for the request that fetch the data of one of the items of module
   * @param { String } id Item Id
   * @returns  { Promise || undefined }
   */
  promiseById: (state) => (id) => {
    return state.promises[id]
  },

  /**
   * First load of collection ?
   * Returns true if the items collection is empty and a request exists for loading it.
   * @returns { Boolean }
   */
  loading: (state, getters) => {
    return getters.get.length === 0 && !!getters.promise
  },

  /**
   * Refresh of collection ?
   * Returns true if the items collection is not empty and a request exists for loading it
   * @returns { Boolean }
   */
  fetching: (state, getters) => {
    return getters.get.length > 0 && !!getters.promise
  },

  /**
   * First load of single item ?
   * Returns true if the item does not exists in store and a request exists for loading it
   * @param { String } id Item Id
   * @returns { Boolean }
   */
  loadingItem: (state, getters) => (id) => {
    return getters.loading
      || (!getters.getById(id) && !!getters.promiseById(id))
  },

  /**
   * Refresh of single item ?
   * Returns true if the item exists in store and a request exists for loading it
   * @param { String } id Item Id
   * @returns { Boolean }
   */
  fetchingItem: (state, getters) => (id) => {
    return getters.fetching
      || (!!getters.getById(id) && !!getters.promiseById(id))
  },

  /**
   * First load of relationship ?
   * Returns true if the related collection is empty and a request exists for loading it.
   * @param { String } id Source item Id
   * @param { String } name Relationship name
   * @returns { Boolean }
   */
  loadingRelationship: (state, getters) => (id, name) => {
    return !getters.getRelationship(id, name) && !!getters.promiseById(id + '_' + name)
  },

  /**
   * Refresh of relationship ?
   * Returns true if the related collection is not empty and a request exists for loading it.
   * @param { String } id Source item Id
   * @param { String } name Relationship name
   * @returns { Boolean }
   */
  fetchingRelationship: (state, getters) => (id, name) => {
    return !!getters.getRelationship(id, name) && !!getters.promiseById(id + '_' + name)
  },

  /**
   * First load of related single item ?
   * Returns true if the related item does not exists in store and a request exists for loading it.
   * @param { String } id Source item Id
   * @param { String } name Relationship name
   * @param { String } relId Related item Id
   * @returns { Boolean }
   */
  loadingRelated: (state, getters) => (id, name, relId) => {
    return getters.loadingRelationship(id, name)
      || (!getters.getRelated(id, name, relId) && !!getters.promiseById(relId))
  },

  /**
   * Refresh of related single item ?
   * Returns true if the related item exists in store and a request exists for loading it.
   * @param { String } id Source item Id
   * @param { String } name Relationship name
   * @param { String } relId Related item Id
   * @returns { Boolean }
   */
  fetchingRelated: (state, getters) => (id, name, relId) => {
    return getters.fetchingRelationship(id, name)
      || (!!getters.getRelated(id, name, relId) && !!getters.promiseById(relId))
  },
}