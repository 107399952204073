<template>
	<v-row justify="center">
		<v-dialog v-model="showdialog" persistent max-width="800px">
			<v-card>
				<v-card-title>
					<span class="text-h5">News</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-text-field label="Title" outlined v-model="News.Name"></v-text-field>
						</v-row>
						<v-row>
							<v-textarea label="Content" outlined v-model="News.Description"></v-textarea>
						</v-row>
						<v-row>
							<v-alert outlined type="error" dense v-if="errorUpdateMessage != ''">
								{{ errorUpdateMessage }}
							</v-alert>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="CloseDialog">
						Close
					</v-btn>
					<v-btn color="blue darken-1" text @click="Save">
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			News: {},
			errorUpdateMessage: "",
		};
	},
	watch: {
		InitialData: function(Input) {
			const _this = this;
			_this.News = Input;
		},
	},
	props: ["showdialog", "InitialData"],
	methods: {
		CloseDialog() {
			this.$emit("update:dialog", false);
		},
		Save() {
			const _this = this;
			let action = "add";
			if (_this.News.Id) action = "update";
			this.$store
				.dispatch(`news/${action}`, this.News)
				.then(() => {
					_this.News = {};
					_this.CreateNew = false;
					_this.errorUpdateMessage = "";
					_this.$emit("update:dialog", false);
				})
				.catch((error) => {
					_this.errorUpdateMessage = error.response.data.details.map((x) => x.message).join("</br>");
				});
		},
	},
};
</script>

<style scoped>
div {
	text-align: left;
}
</style>
