<template>
	<v-layout row wrap align-center>
		<v-flex xs8 offset-md2 v-if="!loading">
			<NewsEdit :showdialog="OpenDialog" :InitialData="NewsToEdit" v-on:update:dialog="OpenDialog = $event"></NewsEdit>
			<v-data-iterator :items="News" :items-per-page.sync="itemsPerPage" :page.sync="page" :search="search" hide-default-footer :sort-by="sortBy" :sort-desc="true">
				<template v-slot:header>
					<v-toolbar class="mb-1">
						<v-text-field v-model="search" clearable flat hide-details prepend-inner-icon="mdi-magnify" label="Search"></v-text-field>
						<v-btn class="mx-2" fab dark color="indigo" v-if="IsAdministrator" @click="CreateNew" x-small>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-toolbar>
				</template>
				<template v-slot:default="props">
					<v-card v-for="item in props.items" :key="item.id" elevation="6" class="mt-4 mb-4">
						<v-card-title>
							<v-container fill-height fluid>
								<v-layout>
									<v-flex class="d-flex">
										<span class="mr-auto">{{ item.Name }}</span>
										<v-btn class="ma-1" fab x-small @click="Delete(item.Id)" v-if="IsAdministrator">
											<v-icon>mdi-delete</v-icon>
										</v-btn>
										<v-btn class="ma-1" fab x-small @click="Edit(item.Id)" v-if="IsAdministrator">
											<v-icon>mdi-pen</v-icon>
										</v-btn>
									</v-flex>
								</v-layout>
							</v-container>
						</v-card-title>
						<v-card-text>
							<MarkdownRender :content="item.Description" />
						</v-card-text>
						<v-card-subtitle>
							<v-row>
								<v-col>Published on {{ DateToLocale(item.createdAt) }}</v-col>
								<v-col class="float-right">
									<v-chip-group active-class="primary--text" column>
										<v-chip v-for="tag in item.tags" :key="tag">
											{{ tag }}
										</v-chip>
									</v-chip-group>
								</v-col>
							</v-row>
						</v-card-subtitle>
					</v-card>
				</template>
				<template v-slot:footer>
					<v-row class="mt-2" align="center" justify="center">
						<span class="grey--text">Items per page</span>
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on">
									{{ itemsPerPage }}
									<v-icon>mdi-chevron-down</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" @click="itemsPerPage = number">
									<v-list-item-title>{{ number }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
						<v-spacer></v-spacer>
						<v-pagination v-model="page" :length="PageNumber" circle></v-pagination>
					</v-row>
				</template>
			</v-data-iterator>
		</v-flex>
		<v-container v-else fluid class="d-flex justify-center mb-6">
			<v-img max-height="64" max-width="64" contain src="../assets/Walk.gif" />
		</v-container>
	</v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import NewsEdit from "../components/Editors/NewsEdit";
import MarkdownRender  from "../components/MarkdownRender";
export default {
	components: { NewsEdit , MarkdownRender},
	data() {
		return {
			itemsPerPageArray: [2, 4, 8, 12],
			search: "",
			filter: {},
			sortDesc: false,
			page: 1,
			itemsPerPage: 4,
			sortBy: "createdAt",
			valid: null,
			model: null,
			OpenDialog: false,
			NewsToEdit: {},
		};
	},
	computed: {
		...mapGetters("news", { News: "get", loading: "promise" }),
		...mapGetters("profile", ["IsAdministrator", "isConnected"]),
		PageNumber: function() {
			return Math.ceil((this.News || []).length / this.itemsPerPage);
		},
	},
	watch: {
		isConnected: function() {
			if (this.News.length == 0 && this.isConnected) this.$store.dispatch("news/fetchAll");
		},
	},
	created() {
		if (this.News.length == 0 && this.isConnected) this.$store.dispatch("news/fetchAll");

	},
	methods: {
		DateToLocale(_date) {
			var dte = new Date(Date.parse(_date));
			const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
			return dte.toLocaleDateString(undefined, options);
		},
		CreateNew() {
			this.OpenDialog = true;
			this.NewsToEdit = {};
		},
		Save() {
			const _this = this;
			this.$store
				.dispatch("data/addNews", this.CreateNews)
				.then(() => {
					_this.CreateNews = {};
					_this.CreateNew = false;
					_this.errorUpdateMessage = "";
				})
				.catch((error) => {
					_this.errorUpdateMessage = error.response.data.details.map((x) => x.message).join("</br>");
				});
		},
		Edit(Id) {
			this.NewsToEdit = this.$store.getters[`news/getById`](Id);
			this.OpenDialog = true;
		},
		Delete(Id) {
			const _this = this;
			this.$store
				.dispatch("news/delete", Id)
				.then(() => {
					_this.errorUpdateMessage = "";
				})
				.catch((error) => {
					_this.errorUpdateMessage = error.response.data.details.map((x) => x.message).join("</br>");
				});
		},
	},
};
</script>

<style scoped>
div {
	text-align: left;
}
</style>
