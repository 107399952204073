/**
 * State
 * 
 * Store state : The store structure
 */
export default {
  data: () => ({
    /**
     * Current API endpoint name for module
     */
    name: '',

    /**
     * All items indexed by Id
     */
    all: {},

    /** 
     * Index of promises (keys generated from Id)
     */
    promises: {},
  })
}