import Vue from "vue";
import App from "./App.vue";
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import router from "./router";
import store from "./store";
import { Auth } from "aws-amplify";

import "@aws-amplify/ui-vue";
import aws_exports from "./aws-exports";
import vuetify from "./plugins/vuetify";

//Amplify.Logger.LOG_LEVEL = 'DEBUG';
if (document.location.hostname == "localhost") aws_exports.aws_cloud_logic_custom.find((x) => x.name == "api6b3f7a6e").endpoint = "http://localhost:45";

Amplify.configure(aws_exports);
Auth.configure({
	cookieStorage: {
		domain: document.location.hostname,
		path: "/",
		expires: 365,
		secure: false,
	},
});

Vue.config.productionTip = false;
Vue.use(AmplifyPlugin, AmplifyModules);
new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
