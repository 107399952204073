import { API } from "aws-amplify";

export default {
	namespaced: true,
	state: {
		darktheme: false,
		collapsedsidebar: false,
		isAwake: false,
	},
	getters: {
		isDarkTheme(state) {
			return state.darktheme;
		},
		isCollapsedSideBar(state) {
			return state.collapsedsidebar;
		},
		isAwake(state) {
			return state.isAwake;
		},
	},
	actions: {
		LambdaWakeUp({ commit }) {
			return new Promise((resolve, reject) => {
				API.get("api6b3f7a6e", "/Ping")
					.then(() => {
						commit(`SetIsAwake`);
						resolve(true);
					})
					.catch((err) => reject(err));
			});
		},
	},
	mutations: {
		SetDarkTheme(state, value) {
			state.darktheme = value;
		},
		SetCollapsed(state, value) {
			state.collapsedsidebar = value;
		},
		SetIsAwake(state) {
			state.isAwake = true;
		},
	},
};
