<template>
	<v-app>
		<v-app-bar app clipped-left>
			<v-app-bar-nav-icon @click.stop="openedMenu = !openedMenu" v-if="!$vuetify.breakpoint.lgAndUp" />
			<v-img v-if="$vuetify.theme.dark" class="shrink mr-2" contain src="./assets/Minerva_dark.png" transition="scale-transition" height="50"></v-img>
			<v-img v-else class="shrink mr-2" contain src="./assets/Minerva.png" transition="scale-transition" height="50"></v-img>
			<v-spacer />
			<v-toolbar-title class="mr-12">Customer Portal</v-toolbar-title>

			<v-menu left bottom offset-y v-if="isConnected">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on">
						<v-avatar color="primary">
							<v-icon dark v-if="!user.pictureurl">
								mdi-account-circle
							</v-icon>
							<v-img v-if="user.pictureurl" :src="user.pictureurl"> </v-img>
						</v-avatar>
					</v-btn>
				</template>
				<v-list>
					<v-list-item :to="{ path: '/Profile' }">
						<v-list-item-title>My Profile</v-list-item-title>
					</v-list-item>
					<v-list-item @click="SignOut">
						<v-list-item-title>SignOut</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		<v-navigation-drawer app clipped :permanent="openedMenu" v-if="isConnected && isAwake" :mini-variant="isCollapsedSideBar">
			<v-layout align-center justify-space-between column fill-height class="pa-0 ma-0">
				<v-list style="width:100%">
					<v-list-item-group color="primary">
						<v-list-item v-for="(item, i) in items" :key="i" :to="item.route">
							<v-list-item-icon>
								<v-icon v-text="item.icon"></v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								{{ item.text }}
							</v-list-item-content>
						</v-list-item>
						<v-list-item link @click="SignOut">
							<v-list-item-icon>
								<v-icon>mdi-power</v-icon>
							</v-list-item-icon>
							<v-list-item-content>Log Out</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
				<v-list style="width:100%">
					<v-list-item :ripple="false" justify-end flat exact icon @click="SetCollapsed(!isCollapsedSideBar)">
						<v-list-item-icon>
							<v-icon v-if="isCollapsedSideBar">mdi-chevron-double-right</v-icon>
							<v-icon v-else>mdi-chevron-double-left</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Collapse Sidebar</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-layout>
		</v-navigation-drawer>
		<v-main app>
			<!-- Provides the application the proper gutter -->
			<v-container>
				<!-- If using vue-router -->
				<router-view v-if="isAwake || !isConnected"></router-view>
				<img v-else src="./assets/Walk.gif" />
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import { Auth } from "aws-amplify";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { AmplifyEventBus } from "aws-amplify-vue";
export default {
	name: "App",
	methods: {
		...mapMutations("application", ["SetCollapsed"]),
		...mapActions("application", ["LambdaWakeUp"]),
		SignOut: function() {
			const _this = this;
			Auth.signOut().then(() => {
				_this.$store
					.dispatch("profile/getSession")
					.then(_this.$router.push("/Login"))
					.catch((x) => {
						console.log(x);
					});
			});
		},
	},
	data: () => ({
		openedMenu: null,
	}),
	computed: {
		...mapState({
			user: (state) => state.profile.user,
		}),
		...mapGetters("profile", ["isConnected", "IsAdministrator"]),
		...mapGetters("application", ["isDarkTheme", "isCollapsedSideBar", "isAwake"]),
		...mapGetters("products", { Products: "get" }),
		items: function() {
			let dynamic = this.Products.map((x) => {
				return { text: x.Name, icon: x.Icon ? x.Icon : "mdi-account", route: { path: `/Products/${x.Id}` } };
			});
			let userManagement = this.IsAdministrator ? [{ text: "User Management", icon: "mdi-account-supervisor", route: { path: `/Admin/Users` } }] : [];
			let collection = [{ text: "News", icon: "mdi-newspaper-variant-outline", route: { path: "/" } }, ...dynamic, ...userManagement, { text: "My Profile", icon: "mdi-account", route: { path: "/Profile" } }];
			return collection;
		},
	},
	watch: {
		isConnected: function() {
			if (this.isConnected && this.Products.length == 0) this.$store.dispatch("products/fetchAll");
		},
	},
	created() {
		if (this.isConnected) {
			console.log("FetchAll on created .");
			this.$store.dispatch("products/fetchAll").then(() => {
				this.$store.dispatch("application/SetIsAwake");
			});
		}
	},
	mounted() {
		const _this = this;
		this.$vuetify.theme.dark = this.isDarkTheme;
		this.openedMenu = this.$vuetify.breakpoint.lgAndUp;
		AmplifyEventBus.$on("authState", (evt) => {
			console.log(evt);
			console.log("getSession on Amplify Event .");
			_this.$store.dispatch("profile/getSession").then(() => {
				console.log("fetchAll on Amplify Event .");
				_this.$store.dispatch("products/fetchAll");
			});
		});
		this.$store.subscribe((mutation) => {
			if (mutation.type === "application/SetDarkTheme") {
				this.$vuetify.theme.dark = mutation.payload;
			}
		});
	},
};
</script>

<style>
#app {
	text-align: center;
}
#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
:root {
	/* Colors */
	--amazonOrange: #b7022d !important;
	--lightAmazonOrange: #b7022d !important;
	--darkAmazonOrange: #b7022d !important;
}
</style>
