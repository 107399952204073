import Vue from "vue";
import VueRouter from "vue-router";
import News from "../views/News.vue";
import Product from "../views/Product.vue";
import Login from "../components/Login.vue";
import Profile from "../views/Profile.vue";
import Roadmap from "../views/Roadmap.vue";
import NotFound from "../views/NotFound.vue";
import Users from "../views/Users.vue";

import store from "../store";
Vue.use(VueRouter);

const routes = [
	{
		path: "/Documentation*"
	},
	{
		path: "/MinervaLibrary*"
	},
	{
		path: "/Login",
		name: "Login",
		component: Login,
	},
	{
		path: "/",
		name: "News",
		component: News,
		meta: { requiresAuth: true },
	},
	{
		path: "/Products/:id",
		name: "Products",
		component: Product,
		meta: { requiresAuth: true },
	},
	{
		path: "/profile",
		name: "Profile",
		meta: { requiresAuth: true },
		component: Profile,
	},
	{
		path: "/Products/DevOps/Roadmap",
		name: "Roadmap",
		meta: { requiresAuth: true },
		component: Roadmap,
	},
	{
		path: "/Admin/Users",
		name: "Users",
		meta: { requiresAuth: true },
		component: Users,
	},
	{
		path: "*",
		name: "NotFound",
		component: NotFound,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeResolve(async (to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
			if (data && data.signInUserSession) {
				if(store.state.profile.user == "")
					store.dispatch("profile/getSession");
				next();
			} else {
				next({ name: "Login", query: { redirectTo: to.name } });
			}
		}).catch(()=>{
			next({ name: "Login", query: { redirectTo: to.name } });
		});
	}
	next();
});
export default router;
