<template>
	<v-layout row wrap align-center>
		<v-flex xs8 offset-md2 v-if="!loading">
			<ProductVersionsEdit :showdialog="OpenDialogVersion" :InitialData="VersionToEdit" :ParentId="ParentId" v-on:update:dialog="OpenDialogVersion = $event"></ProductVersionsEdit>
			<DeliverablesEdit :showdialog="OpenDialogDeliverable" :InitialData="VersionToEdit" :ParentId="ParentId" :DeliverableIndex="DeliverableIndex" v-on:update:dialog="OpenDialogDeliverable = $event"></DeliverablesEdit>
			<v-data-iterator :items="FilteredProductsVersions" sort-by="createdAt" :sort-desc="true" :items-per-page="-1" hide-default-footer>
				<template v-slot:header>
					<v-toolbar class="mb-1">
						<v-text-field v-model="search" clearable flat hide-details prepend-inner-icon="mdi-magnify" label="Search"></v-text-field>
						<v-btn class="mx-2" fab dark color="indigo" v-if="IsAdministrator" @click="CreateNewVersion" x-small>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-toolbar>
				</template>
				<template v-slot:default="props">
					<v-expansion-panels multiple v-model="panel">
						<v-expansion-panel v-for="item in props.items" :key="item.Id">
							<v-expansion-panel-header>
								<v-flex class="d-flex">
									<span class="mr-auto">
										<h3>{{ item.Name }}</h3>
									</span>
									<v-btn class="ma-2" fab x-small outlined dark color="indigo" @click.stop="EditVersion(item.Id)" v-if="IsAdministrator">
										<v-icon>mdi-pen</v-icon>
									</v-btn>
									<v-btn class="ma-2" fab x-small outlined dark color="indigo" @click.stop="DeleteVersion(item.Id)" v-if="IsAdministrator">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-flex>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-row class="pa-6"><MarkdownRender :content="item.Description"></MarkdownRender></v-row>

								<draggable class="list-group" tag="div" v-model="item.Documents" v-bind="dragOptions" @start="drag = true" @end="SaveVersion(item.Id)">
									<v-list-item slot="header">
										<v-flex class="d-flex">
											<span class="mr-auto"><h3>Resources</h3></span>
											<v-btn class="mx-2" fab dark color="indigo" v-if="IsAdministrator" @click="CreateNewDeliverable(item.Id)" x-small>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</v-flex>
									</v-list-item>
									<v-list-item v-bind:class="{ 'list-group-item': IsAdministrator }" v-for="(document, index) in item.Documents" :key="index">
										<v-list-item-content>
											<v-flex class="d-flex">
												<span class="mr-auto">
													<a :href="ComputedUrls[document.Link]" :download="document.Type == 'download'" :target="document.Target">{{ document.Name }}</a>
												</span>
												<v-btn class="ma-2" fab x-small outlined dark color="indigo" @click="EditDeliverable(item.Id, index)" v-if="IsAdministrator">
													<v-icon>mdi-pen</v-icon>
												</v-btn>
												<v-btn class="ma-2" fab x-small outlined dark color="indigo" @click="DeleteDeliverable(item.Id, index)" v-if="IsAdministrator">
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</v-flex>
										</v-list-item-content>
									</v-list-item>
								</draggable>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</template>
			</v-data-iterator>
		</v-flex>
		<v-container v-else fluid  class="d-flex justify-center mb-6">
			<v-img max-height="64" max-width="64" contain src="../assets/Walk.gif" />
		</v-container>

		<v-snackbar v-model="Notify" :timeout="2000" color="green">Item Saved</v-snackbar>
	</v-layout>
</template>

<script>
import { Auth } from "aws-amplify";
const { getSignedUrl } = require("@aws-sdk/s3-request-presigner");
const { S3Client, GetObjectCommand } = require("@aws-sdk/client-s3");

import MarkdownRender  from "../components/MarkdownRender";
import draggable from "vuedraggable";
import ProductVersionsEdit from "../components/Editors/ProductVersionsEdit";
import DeliverablesEdit from "../components/Editors/DeliverablesEdit";
import { mapGetters } from "vuex";
export default {
	components: { ProductVersionsEdit, DeliverablesEdit, draggable,MarkdownRender },
	watch: {
		$route() {
			this.fetch();
			this.panel = [0];
		},
	},
	data() {
		return {
			ParentId: "",
			itemsPerPageArray: [2, 4, 8, 12],
			search: "",
			filter: {},
			sortDesc: false,
			page: 1,
			itemsPerPage: 400,
			sortBy: "createdAt",
			OpenDialogVersion: false,
			VersionToEdit: {},
			OpenDialogDeliverable: false,
			DeliverableIndex: null,
			panel: [0],
			ComputedUrls: {},
			Notify: false,
			drag: false,
		};
	},
	computed: {
		...mapGetters("profile", ["IsAdministrator"]),
		...mapGetters("products", { AllProducts: "get" }),
		FilteredProductsVersions: function() {
			let Items = this.$store.getters[`products/getRelationship`](this.$route.params.id, "ProductVersion");
			return Items;
		},
		loading: function() {
			return this.$store.getters[`products/promiseById`](`${this.$route.params.id}_ProductVersion`) != null;
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				ghostClass: "ghost",
				disable: false,
			};
		},
	},
	mounted() {
		this.fetch();
	},
	methods: {
		async GetComputedUrl(document) {
			let credentials = await Auth.currentCredentials();
			const s3Configuration = {
				credentials: credentials,
				region: "eu-west-1",
			};
			const s3 = new S3Client(s3Configuration);
			let result = await getSignedUrl(s3, new GetObjectCommand({ Bucket: "com.minerva-plm.devops.prereq", Key: document.Link }), { expiresIn: 15 * 60 });
			return result;
		},
		async fetch() {
			const _this = this;
			this.ParentId = this.$route.params.id;
			this.$store.dispatch("products/fetchRelationship", { id: this.$route.params.id, name: "ProductVersion" }).then((results) => {
				results?.map((item) => {
					if (item?.Documents) {
						let credentials = Auth.currentCredentials();
						item.Documents.map((x) => {
							if (x.Type == "download") {
								const s3Configuration = {
									credentials: credentials,
									region: "eu-west-1",
								};
								const s3 = new S3Client(s3Configuration);
								getSignedUrl(s3, new GetObjectCommand({ Bucket: "com.minerva-plm.devops.prereq", Key: x.Link }), { expiresIn: 15 * 60 }).then((result) => {
									_this.$set(_this.ComputedUrls, x.Link, result);
								});
							} else {
								_this.$set(_this.ComputedUrls, x.Link, x.Link);
							}
						});
					}
				});
			});
		},
		CreateNewVersion() {
			this.VersionToEdit = {};
			this.OpenDialogVersion = true;
		},
		CreateNewDeliverable(Id) {
			this.VersionToEdit = this.$store.getters[`products/getRelated`](this.ParentId, "ProductVersion", Id);
			this.DeliverableIndex = null;
			this.OpenDialogDeliverable = true;
		},
		EditVersion(Id) {
			this.VersionToEdit = this.$store.getters[`products/getRelated`](this.ParentId, "ProductVersion", Id);
			this.OpenDialogVersion = true;
		},
		SaveVersion(Id) {
			const _this = this;
			_this.drag = false;
			this.$store
				.dispatch(`products/updateRelated`, { name: "ProductVersion", item: _this.FilteredProductsVersions.find((x) => x.Id == Id) })
				.then(() => {
					_this.Notify = true;
				})
				.catch((error) => {
					alert(error);
				});
		},
		EditDeliverable(VersionId, Index) {
			this.VersionToEdit = this.$store.getters[`products/getRelated`](this.ParentId, "ProductVersion", VersionId);
			this.DeliverableIndex = Index;
			this.OpenDialogDeliverable = true;
		},
		DeleteVersion(Id) {
			const _this = this;
			this.$store
				.dispatch("products/deleteRelated", { sourceName: "Products", id: _this.ParentId, name: "ProductVersion", related_id: Id })
				.then(() => {
					_this.errorUpdateMessage = "";
				})
				.catch((error) => {
					_this.errorUpdateMessage = error.response.data.details.map((x) => x.message).join("</br>");
				});
		},
		DeleteDeliverable(VersionId, Index) {
			let VersionToEdit = this.$store.getters[`products/getRelated`](this.ParentId, "ProductVersion", VersionId);
			VersionToEdit.Documents.splice(Index, 1);
			this.$store
				.dispatch(`products/updateRelated`, { name: "ProductVersion", item: VersionToEdit })
				.then(() => {})
				.catch((error) => {
					const errorUpdateMessage = error.response.data.details.map((x) => x.message).join("</br>");
					console.log(errorUpdateMessage);
				});
		},
	},
};
</script>

<style scoped>
div {
	text-align: left;
}

.button {
	margin-top: 35px;
}
.flip-list-move {
	transition: transform 0.5s;
}
.no-move {
	transition: transform 0s;
}
.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}
.list-group {
	min-height: 20px;
}
.list-group-item {
	cursor: move;
}
.list-group-item i {
	cursor: pointer;
}
</style>
