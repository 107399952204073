import state from "../base/state";
import getters from "../base/getters";
import mutations from "../base/mutations";
import actions from "../base/actions";
/**
 * Group store
 */
export default {
	namespaced: true,
	state: {
		...state.data(),

		/**
		 * Store name
		 */
		name: "Products",
	},
	getters,
	actions,
	mutations
};
