<template>
	<v-container class="pa-4 pa-sm-6 pa-md-8 roadmap" fluid  tag="section">
		<h1>Minerva DevOps Roadmap</h1>

		<!--Legend-->
		<v-row>
			<v-col md="6">
				For 10 years, Minerva is implementing custom solutions using Aras Innovator. Steps by steps, we've created complete and complex toolchain in order to be able to run our implementations.
				<br /><br />
				It's now time for us to share this experience in order to offer a complete visions of DevOps around Aras Innovator. We've categorized and estimate a maturity level in order to share the vision of our roadmap.
				<br /><br />
				<b>Our vision : Why should you spend time and energy on DevOps ? Focus on delivering value to your business</b>
			</v-col>
			<v-col md="6">
				<table class="table">
					<tbody>
						<tr v-bind:key="s.state" v-for="s in states">
							<td>
								<div class="text-center col ma-0 pa-0">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.457 87.457" preserveAspectRatio="xMidYMid meet" style="height:25px" v-html="s.svgDef"></svg>
								</div>
								<div class="text-center col ma-0 pa-0">
									{{ s.state }}
								</div>
							</td>
							<td class="text-left col">
								{{ s.description }}
							</td>
						</tr>
					</tbody>
				</table>
			</v-col>
		</v-row>

		<v-tabs background-color="transparent" v-model="tab">
			<v-tab>Features maturity</v-tab>
			<v-tab>Features description</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab">
			<v-tab-item>
				<v-card flat>
					<v-card-text>
						<v-form>
							<v-row>
								<v-col>
									<v-radio-group row v-model="application.targetRelease">
										<v-radio label="Today" value="Today"></v-radio>
										<v-radio label="R1" value="R1"></v-radio>
										<v-radio label="R2" value="R2"></v-radio>
										<v-radio label="R3" value="R3"></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
						</v-form>

						<!--EPIC Vue based-->
						<v-simple-table>
							<template v-slot:default>
									<thead>
										<tr>
											<th :key="s.name" scope="col" v-for="s in stages">{{ s.name }}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td :key="s.name" v-for="s in stages" style="vertical-align:top">
												<div :key="f.name" v-for="f in getFeaturesPerStage(s.name)" class="row mt-3">
													<div class="ml-5">
														<svg class="float-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.457 87.457" preserveAspectRatio="xMidYMid meet" style="height:15px" v-html="getMaturitySvg(f.name, application.targetRelease)"></svg>
													</div>
													<div class="ml-5">{{ f.name }}</div>
													<br />
												</div>
											</td>
										</tr>
									</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-tab-item>
			<v-tab-item>
				<!--Roadmap vue based-->
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th scope="row">Features</th>
								<th scope="row">Category</th>
								<th scope="row">Tooling</th>
								<th scope="row">Description</th>
								<th v-bind:key="re.name" scope="col" v-for="re in releases">{{ re.name }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-bind:key="fe.name" v-for="fe in features">
								<th scope="row">{{ fe.name }}</th>
								<td scope="row">{{ fe.stage }}</td>
								<td scope="row">{{ fe.tool }}</td>
								<td scope="row" class="text-left">{{ fe.description }}</td>
								<td v-bind:key="re.name" scope="col" v-for="re in releases">
									<div style="font-weight: 400;">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.457 87.457" preserveAspectRatio="xMidYMid meet" style="height:25px" v-html="getMaturitySvg(fe.name, re.name)"></svg>
									</div>
									<!--div :class="filtered(fe.name, re.name)"></div-->
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-tab-item>
		</v-tabs-items>
	</v-container>
</template>

<script>
export default {
	name: "Roadmap",
	components: {},
	methods: {
		getMaturity(name, release) {
			var maturity = "";
			var fe = this.features.filter((x) => x.name == name);

			if (fe.length > 0) {
				var R = fe[0].maturity.filter((x) => x.targetRelease == release);
				if (R.length > 0) {
					maturity = R[0].state;
				}
			}
			return maturity;
		},
		getMaturitySvg(name, release) {
			var svg = "";
			var state = this.getMaturity(name, release);
			if (state != "") {
				var myState = this.states.filter((x) => x.state == state);
				if (myState.length > 0) {
					svg = myState[0].svgDef;
				}
			}
			return svg;
		},
		getFeaturesPerStage(name) {
			return this.features.filter((x) => x.stage == name);
		},
	},
	data: function() {
		return {
			tab: null,
			application: {
				targetRelease: "Today",
			},
			stages: [{ name: "Plan" }, { name: "Create" }, { name: "Verify" }, { name: "Package" }, { name: "Release" }],
			states: [
				{
					state: "Planned",
					description: "Not implemented yet in Minerva DevOps Power Tools",
					svgDef: '<circle cx="56.661" cy="71.425" r="42.229" stroke="#b7022d" stroke-width="3" transform="translate(-12.933 -27.696)" fill="none"></circle>',
				},
				{
					state: "Minimal",
					description: "Available in Product, not maybe not mature enough for production use",
					svgDef: `<g stroke="#b7022d" transform="translate(-12.933 -27.696)">
              <circle cx="56.661" cy="71.425" r="42.229" fill="none" stroke-width="3"></circle>
              <path fill="none" stroke-width=".17" d="M56.765 28.691V70.92"></path>
              <path fill="none" stroke-width=".1" d="M56.765 70.92H14.403v.4"></path>
              <path fill="#b7022d" stroke-width=".5000562" d="M16.048 69.285c.513-8.653 3.503-16.487 8.888-23.286 1.429-1.804 4.758-5.105 6.554-6.5 4.698-3.647 9.776-6.143 15.303-7.523 2.756-.687 6.584-1.207 8.916-1.21l.902-.001v40.09H15.954z" paint-order="markers stroke fill"></path>
              </g>`,
				},
				{
					state: "Viable",
					description: "Ready to be used in production",
					svgDef: `<g stroke="#b7022d" transform="translate(-12.933 -27.696)"><circle cx="56.661" cy="71.425" r="42.229" fill="none" stroke-width="3"></circle>
            <path fill="none" stroke-width=".24" d="M56.765 28.691v83.79"></path>
            <path stroke-width=".5000562" fill="#b7022d" d="M55.075 112.058c-13.458-.55-25.575-7.581-32.898-19.09-4.18-6.57-6.417-14.858-6.146-22.781.307-8.98 3.3-17.112 8.905-24.188 1.429-1.804 4.758-5.105 6.554-6.5 4.698-3.647 9.776-6.143 15.303-7.523 2.756-.687 6.584-1.207 8.916-1.21l.902-.001V112.148l-.3-.026a42.28 42.28 0 0 0-1.236-.064z" paint-order="markers stroke fill"></path>
            </g>`,
				},
				{
					state: "Complete",
					description: "Intensive use in Production in many implementations",
					svgDef: `<circle cx="56.661" cy="71.425" r="42.229" stroke="#b7022d" stroke-width="3" transform="translate(-12.933 -27.696)" fill="#b7022d"></circle>`,
				},
			],
			releases: [
				{
					name: "Today",
					date: "",
				},
				{
					name: "R1",
					date: " T0 + 6 months",
				},
				{
					name: "R2",
					date: " T0 + 9 months",
				},
				{
					name: "R3",
					date: " T0 + 12 months",
				},
			],

			features: [
				{
					name: "Issue Tracking",
					stage: "Plan",
					description: "Track issues in Gitlab, manage milestones and statuses",
					tool: "Gitlab",
					maturity: [
						{
							targetRelease: "Today",
							state: "Complete",
						},
						{
							targetRelease: "R1",
							state: "Complete",
						},
						{
							targetRelease: "R2",
							state: "Complete",
						},
						{
							targetRelease: "R3",
							state: "Complete",
						},
					],
				},
				{
					name: "Epics Management",
					description: "Plan and manage work between multiple groups by creating Epics, and mapping Issues and milestones to them",
					tool: "Gitlab",
					stage: "Plan",
					maturity: [
						{
							targetRelease: "Today",
							state: "Viable",
						},
						{
							targetRelease: "R1",
							state: "Viable",
						},
						{
							targetRelease: "R2",
							state: "Complete",
						},
						{
							targetRelease: "R3",
							state: "Complete",
						},
					],
				},
				{
					name: "Time Tracking",
					description: "",
					tool: "Gitlab",
					stage: "Plan",
					maturity: [
						{
							targetRelease: "Today",
							state: "Viable",
						},
						{
							targetRelease: "R1",
							state: "Viable",
						},
						{
							targetRelease: "R2",
							state: "Viable",
						},
						{
							targetRelease: "R3",
							state: "Complete",
						},
					],
				},
				{
					name: "Kanban Boards",
					description: "Visualize in a efficient way how teams are progressing.",
					tool: "Gitlab",
					stage: "Plan",
					maturity: [
						{
							targetRelease: "Today",
							state: "Viable",
						},
						{
							targetRelease: "R1",
							state: "Viable",
						},
						{
							targetRelease: "R2",
							state: "Complete",
						},
						{
							targetRelease: "R3",
							state: "Complete",
						},
					],
				},
				{
					name: "Source Code Management",
					description: "Source code management enables coordination, sharing and collaboration across the entire software development team. Track and merge branches, audit changes and enable concurrent work, to accelerate software delivery.",
					tool: "Gitlab",
					stage: "Create",
					maturity: [
						{
							targetRelease: "Today",
							state: "Complete",
						},
						{
							targetRelease: "R1",
							state: "Complete",
						},
						{
							targetRelease: "R2",
							state: "Complete",
						},
						{
							targetRelease: "R3",
							state: "Complete",
						},
					],
				},
				{
					name: "Local deploy",
					description: "In order to develop features in Aras, developers will need to deploy Aras local instances corresponding to their feature branches",
					tool: "Nant Scripts",
					stage: "Create",
					maturity: [
						{
							targetRelease: "Today",
							state: "Minimal",
						},
						{
							targetRelease: "R1",
							state: "Minimal",
						},
						{
							targetRelease: "R2",
							state: "Viable",
						},
						{
							targetRelease: "R3",
							state: "Complete",
						},
					],
				},
				{
					name: "Code Review",
					description: "Review code, discuss changes, share knowledge, and identify defects in code among distributed teams via asynchronous review and commenting. Automate, track and report code reviews.",
					tool: "GitLab",
					stage: "Create",
					maturity: [
						{
							targetRelease: "Today",
							state: "Complete",
						},
						{
							targetRelease: "R1",
							state: "Complete",
						},
						{
							targetRelease: "R2",
							state: "Complete",
						},
						{
							targetRelease: "R3",
							state: "Complete",
						},
					],
				},
				{
					name: "Packaging Aras application",
					description: "Aras as a plateform is providing powerfull features just by configuration. Extracting properly this configuration is a strong need for all developpers.",
					tool: "Minerva Package Manager (AKA Packman)",
					stage: "Create",
					maturity: [
						{
							targetRelease: "Today",
							state: "Minimal",
						},
						{
							targetRelease: "R1",
							state: "Minimal",
						},
						{
							targetRelease: "R2",
							state: "Viable",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "Deployment package",
					description: "Compile all elements of the Repository to a monolithic delivery package",
					tool: "Nant Scripts",
					stage: "Create",
					maturity: [
						{
							targetRelease: "Today",
							state: "Minimal",
						},
						{
							targetRelease: "R1",
							state: "Minimal",
						},
						{
							targetRelease: "R2",
							state: "Viable",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "AML Loader",
					description: "Powerful command line tool to apply AML or SQL queries and upload Files to your Aras instance",
					stage: "Create",
					tool: "Minerva Power Developer tooling",
					maturity: [
						{
							targetRelease: "Today",
							state: "Viable",
						},
						{
							targetRelease: "R1",
							state: "Complete",
						},
						{
							targetRelease: "R2",
							state: "Complete",
						},
						{
							targetRelease: "R3",
							state: "Complete",
						},
					],
				},
				{
					name: "Excel2AML",
					description: "Need to automate a dataload ? Manage your dataset in an Excel file, Excel2Aml will dynamically generate the coresponding AML in order to load the dataset",
					stage: "Create",
					tool: "Minerva Power Developer tooling",
					maturity: [
						{
							targetRelease: "Today",
							state: "Viable",
						},
						{
							targetRelease: "R1",
							state: "Viable",
						},
						{
							targetRelease: "R2",
							state: "Viable",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "Minerva Library",
					description: "10 years of Aras experience for speedup and secure development of your Aras applications",
					stage: "Create",
					tool: "Minerva Power Developer tooling",
					maturity: [
						{
							targetRelease: "Today",
							state: "Minimal",
						},
						{
							targetRelease: "R1",
							state: "Minimal",
						},
						{
							targetRelease: "R2",
							state: "Viable",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "Minerva Library Test Framework",
					description: "Automate your functional testing using our Test Framework. The aim is to be able to script real scenarios in your application in order to be able to run test automatically. Secure your application, improve quality and control of non-regression",
					stage: "Create",
					tool: "Minerva Power Developer tooling",
					maturity: [
						{
							targetRelease: "Today",
							state: "Minimal",
						},
						{
							targetRelease: "R1",
							state: "Minimal",
						},
						{
							targetRelease: "R2",
							state: "Viable",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "Artifact Generation",
					description: "Build automatically your application at each commit, merge, tag in order to make sure that you keep a consistent application under control. ",
					stage: "Verify",
					tool: "GitLab",
					maturity: [
						{
							targetRelease: "Today",
							state: "Minimal",
						},
						{
							targetRelease: "R1",
							state: "Viable",
						},
						{
							targetRelease: "R2",
							state: "Viable",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "Aras Instance Deployment Test",
					description: "At each commit, run continuous integration pipeline and test the deployment of the solution",
					stage: "Verify",
					tool: "GitLab",
					maturity: [
						{
							targetRelease: "Today",
							state: "Planned",
						},
						{
							targetRelease: "R1",
							state: "Minimal",
						},
						{
							targetRelease: "R2",
							state: "Minimal",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "Aras functional Automated Test",
					description: "Develop and run Automated test as a step of a pipeline. Secure the functional behavior of your solution",
					tool: "Gitlab, Minerva Test Framework",
					stage: "Verify",
					maturity: [
						{
							targetRelease: "Today",
							state: "Minimal",
						},
						{
							targetRelease: "R1",
							state: "Minimal",
						},
						{
							targetRelease: "R2",
							state: "Minimal",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "Minerva NuGet Gallery",
					description: "Nuget is the package manager for .NET. Get access to Minerva NuGet Gallery to get all Aras and Minerva related binairies.",
					stage: "Package",
					tool: "NuGet",
					maturity: [
						{
							targetRelease: "Today",
							state: "Planned",
						},
						{
							targetRelease: "R1",
							state: "Minimal",
						},
						{
							targetRelease: "R2",
							state: "Viable",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "Prerequisites Management",
					description: "You need to setup a laptop for developper, or a new server for Aras. Minerva is delivery install scripts in order to install all prerequisites on the machine.",
					stage: "Package",
					tool: "Chocolatey",
					maturity: [
						{
							targetRelease: "Today",
							state: "Planned",
						},
						{
							targetRelease: "R1",
							state: "Minimal",
						},
						{
							targetRelease: "R2",
							state: "Minimal",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "Online Review Instance",
					description: "Commiting to Gitlab will generale deployment pipelines. Get access to the deployed Aras instance to visualize the result. Manage your QA / UAT environments",
					tool: "GilLab",
					stage: "Release",
					maturity: [
						{
							targetRelease: "Today",
							state: "Planned",
						},
						{
							targetRelease: "R1",
							state: "Planned",
						},
						{
							targetRelease: "R2",
							state: "Minimal",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "Artifact Publish",
					description: "Minerva GitLab will generate the artifact of your solution automatically. You'll need this artifact in order to deploy on your local environment. Get access to to your datasource and connect it directly with the Continous Deployement tool.",
					tool: "",
					stage: "Release",
					maturity: [
						{
							targetRelease: "Today",
							state: "Planned",
						},
						{
							targetRelease: "R1",
							state: "Planned",
						},
						{
							targetRelease: "R2",
							state: "Viable",
						},
						{
							targetRelease: "R3",
							state: "Viable",
						},
					],
				},
				{
					name: "Continuous Deployment",
					description: "Automate deployment of your Aras solution on your environments. Minerva will provide all needed targets in order for you to configure and adapt to your infrastructure",
					tool: "Go, Nant Scripts",
					stage: "Release",
					maturity: [
						{
							targetRelease: "Today",
							state: "Planned",
						},
						{
							targetRelease: "R1",
							state: "Minimal",
						},
						{
							targetRelease: "R2",
							state: "Viable",
						},
						{
							targetRelease: "R3",
							state: "Complete",
						},
					],
				},
				{
					name: "Secrets Management",
					description: "Manage all your secrets (passwords ....) in a secured way.",
					stage: "Release",
					tool: "Vault",
					maturity: [
						{
							targetRelease: "Today",
							state: "Planned",
						},
						{
							targetRelease: "R1",
							state: "Planned",
						},
						{
							targetRelease: "R2",
							state: "Planned",
						},
						{
							targetRelease: "R3",
							state: "Minimal",
						},
					],
				},
			],
		};
	},
};
</script>

<style  scoped>
.roadmap {
	text-align: left;
}
</style>
