<template>
	<v-container>
		<v-row>
			<v-col md="9">
				<v-data-table :headers="headers" :items="Users || []" class="elevation-1">
					<template v-slot:top>
						<v-toolbar flat>
							<v-toolbar-title>Users</v-toolbar-title>
							<v-divider class="mx-4" inset vertical></v-divider>
							<v-spacer></v-spacer>
						</v-toolbar>
					</template>
					<template v-slot:[`item.Memberships`]="{ item,value }" style="vertical-align: middle">
						<drop style="height:100%; width:100%; min-height:50px" @drop="function(arg) {Drop(arg.data, item);arg.top.$el.parentNode.parentNode.classList.remove('drop-in');}" @dragenter="DragEnter" @dragleave="DragLeave">
							<div v-if="value">
								<v-chip v-for="membership in value" :key="membership" @click:close="RemoveMembership({UserId : item.Id, ProductId:membership})" close-icon="mdi-delete" close x-small>{{ membership }}</v-chip>
							</div>
						</drop>
					</template>
				</v-data-table>
			</v-col>
			<v-col md="3">
				<v-card class="mx-auto">
					<v-toolbar flat color="deep-purple accent-4" dark>
						<v-toolbar-title>Available Memberships</v-toolbar-title>
					</v-toolbar>
					<v-card-text>
						<h2 class="title mb-2">Choose Groups</h2>
						<v-chip-group column>
							<drag v-for="g in Products" :key="g.Id" :data="g" name="tag" style="float: left;">
								<v-chip outlined>{{ g.Name }}</v-chip>
							</drag>
						</v-chip-group>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { Drop, Drag } from "vue-easy-dnd";
export default {
	name: "Users",
	components: { Drop, Drag },
	data() {
		return {
			headers: [
				{ text: "Username", value: "Username" },
				{ text: "FullName", value: "FullName" },
				{ text: "Email", value: "Email" },
				{ text: "PhoneNumber", value: "PhoneNumber" },
				{ text: "Memberships", value: "Memberships" },
			],
		};
	},
	computed: {
		...mapGetters("products", { Products: "get" }),
		...mapGetters("users", { Users: "get" }),
	},
	methods: {
		Drop: function(data,item) {
			this.$store.dispatch("users/addMembership", {UserId : item.Id, ProductId : data.Id});
		},
		DragEnter(arg) {
			if (arg.top.$el.parentNode.parentNode.localName == "tr") {
				arg.top.$el.parentNode.parentNode.classList.add("drop-in");
			}
		},
		DragLeave(arg) {
			if (arg.previousTop.$el.parentNode.parentNode.localName == "tr") {
				arg.previousTop.$el.parentNode.parentNode.classList.remove("drop-in");
			}
		},
		RemoveMembership(arg){
			this.$store.dispatch("users/removeMembership", arg);
		}
	},

	created() {
		if (this.Users.length == 0) this.$store.dispatch("users/fetchAll");
	},
	async beforeMount() {},
};
</script>

<style scoped>
.drop-in {
	background-color: #e6e6e6;
	border-color: red;
	border-width: 1px;
	border-style: dashed;
}
</style>
