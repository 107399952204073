<template>
	<div>
		<amplify-authenticator :authConfig="{ signInConfig: { isSignUpDisplayed: false } }">
			<amplify-sign-in></amplify-sign-in>
		</amplify-authenticator>
	</div>
</template>

<script>
import "@aws-amplify/ui-vue";
import { AmplifyEventBus } from "aws-amplify-vue";
export default {
	data() {
		return {
			user: undefined,
			authState: undefined,
		};
	},
	beforeMount() {
		console.log("Login");
		const _this = this;
		AmplifyEventBus.$on("authState", (info) => {
			if (info === "signedIn") {
				if (_this.$route.query.redirect_url) {
					window.location.replace(_this.$route.query.redirect_url);
				} else {
					_this.$router.push(_this.$route.query.redirect || "/");
				}
			}
		});
	},
};
</script>

<style></style>
