import { API } from "aws-amplify";

/**
 * Store.Base.Actions
 *
 * Store actions : Asynchonous calls that modifies data in store
 */
export default {
	/**
	 * Request API to get all items, add them in store and return the list of all items from store
	 * @async
	 * @returns { [ Object ] || [] }
	 */
	fetchAll({ getters, commit, state }) {
		let promise = getters.promise;
		if (!promise) {
			promise = new Promise((resolve, reject) => {
				API.get("api6b3f7a6e", `/${state.name}`)
					.then((res) => {
						if (res.Items.length) commit("setCollection", res.Items);
						resolve(getters.get);
					})
					.catch((e) => reject(e))
					.finally(() => {
						commit("clearPromise");
					});
			});
			commit("setPromise", promise);
		}
		return promise;
	},

	/**
	 * Request API to get one item, add it in store and returns it from store
	 * @async
	 * @param { String } id The Id of the item to fetch
	 * @returns { Object }
	 * @throws { Exception } item not found
	 */
	fetchById({ getters, commit, state }, id) {
		let promise = getters.promiseById(id);
		if (!promise) {
			promise = new Promise((resolve, reject) => {
				API.get("api6b3f7a6e", `/${state.name}/${id}`)
					.then((res) => {
						const nb = Object.keys(res).length;
						if (nb) {
							commit("setItem", res);
							resolve(getters.getById(id));
						} else reject(`${state.name} not found: ${id}.`);
					})
					.catch((e) => reject(e))
					.finally(() => {
						commit("clearPromiseById", id);
					});
			});
			commit("setPromiseById", { id, promise });
		}
		return promise;
	},

	/**
	 * Request API to get all items by relationship name an source id,
	 * add them in store and return the list of all items from store
	 * @async
	 * @param { String } id The Id of the source item
	 * @param { String } name The name of the relationship
	 * @returns { [ Object ] || [] }
	 */
	fetchRelationship({ getters, commit, state }, { id, name }) {
		const promiseId = id + "_" + name;
		let promise = getters.promiseById(promiseId);
		if (!promise) {
			promise = new Promise((resolve, reject) => {
				API.get("api6b3f7a6e", `/${state.name}/${id}/${name}s`)
					.then((res) => {
						if (res.Items.length) commit("setRelationship", { id, name, items: res.Items });
						const results = getters.getRelationship( id, name )
						resolve(results);
					})
					.catch((e) => reject(e))
					.finally(() => {
						commit("clearPromiseById", promiseId);
					});
			});
			commit("setPromiseById", { id: promiseId, promise });
		}
		return promise;
	},

	/**
	 * Request API to get a single related item, add it in store and returns it from store
	 * @async
	 * @param { String } id The Id of the source item
	 * @param { String } name The name of the relationship
	 * @param { String } relId The Id of the related item to fetch
	 * @returns { Object }
	 * @throws { Exception } item not found
	 */
	fetchRelated({ getters, commit }, { id, name, relId }) {
		let promise = getters.promiseById(relId);
		if (!promise) {
			promise = new Promise((resolve, reject) => {
				API.get("api6b3f7a6e", `/${name}s/${relId}`)
					.then((res) => {
						const nb = Object.keys(res).length;
						if (nb) {
							commit("setRelated", { id, name, item: res });
							resolve(getters.getRelated(id, name, relId));
						} else reject(`${name} not found: ${relId}.`);
					})
					.catch((e) => reject(e))
					.finally(() => {
						commit("clearPromiseById", relId);
					});
			});
			commit("setPromiseById", { id: relId, promise });
		}
		return promise;
	},

	/**
	 * Request API to create a new item,
	 * If the creation succeed, the item is saved in store and returned from store
	 * @async
	 * @param { Object } item The item to add
	 * @returns { Object }
	 */
	add: function({ getters, commit, state }, item) {
		return new Promise((resolve, reject) => {
			API.post("api6b3f7a6e", `/${state.name}`, {
				body: item,
			})
				.then((res) => {
					commit("setItem", res);
					resolve(getters.getById(res.Id));
				})
				.catch((e) => reject(e));
		});
	},

	/**
	 * Request API to create a related item by relationship name and source Id,
	 * If the creation succeed, the item is saved in store, attached to parent and returned from store
	 * @async
	 * @param { String } id The Id of the source item
	 * @param { String } name The name of the relationship
	 * @param { Object } item The item to add
	 * @returns { Object }
	 */
	addRelated: function({ getters, commit, state }, { id, name, item }) {
		return new Promise((resolve, reject) => {
			API.post("api6b3f7a6e", `/${state.name}/${id}/${name}s`, {
				body: item,
			})
				.then((res) => {
					commit("setRelated", { id, name, item: res });
					resolve(getters.getRelated(id, name, res.Id));
				})
				.catch((e) => reject(e));
		});
	},

	/**
	 * Request API to update an existing item,
	 * If the update succeed, the new item version replace the old one in store and is returned from store
	 * @async
	 * @param { Object } item The modified item. The Id property MUST be defined
	 * @returns { Object }
	 */
	update: function({ getters, commit, state }, item) {
		return new Promise((resolve, reject) => {
			API.put("api6b3f7a6e", `/${state.name}`, {
				body: item,
			})
				.then((res) => {
					commit("setItem", res);
					resolve(getters.getById(res.Id));
				})
				.catch((e) => reject(e));
		});
	},

	/**
	 * Request API to update a single related item,
	 * If the update succeed, the new item version replace the old one in store and is returned from store
	 * @async
	 * @param { String } name The name of the relationship
	 * @param { Object } item The item to add
	 * @returns { Object }
	 */
	updateRelated: function({ getters, commit }, { name, item }) {
		return new Promise((resolve, reject) => {
			API.put("api6b3f7a6e", `/${name}s`, {
				body: item,
			})
				.then((res) => {
					commit("setRelated", { id: res.Id, name, item: res });
					resolve(getters.getRelated({ id: res.Id, name }));
				})
				.catch((e) => reject(e));
		});
	},

	/**
	 * Request API to delete an existing item,
	 * If the delete succeed, the item is also removed from store and a success indicator is returnd
	 * @async
	 * @param { String } id The Id of the item to delete
	 * @returns { success: Boolean, deleted: String }
	 */
	delete({ commit, state }, id) {
		return new Promise((resolve, reject) => {
			API.del("api6b3f7a6e", `/${state.name}/${id}`)
				.then(() => {
					commit("removeItem", id);
					resolve({
						success: true,
						deleted: id,
					});
				})
				.catch((e) => reject(e));
		});
	},

	/**
	 * Request API to delete an existing item,
	 * If the delete succeed, the item is also removed from store and a success indicator is returnd
	 * @async
	 * @param { String } id The Id of the item to delete
	 * @returns { success: Boolean, deleted: String }
	 */
	deleteRelated({ commit }, { id, sourceName, name, related_id }) {
		return new Promise((resolve, reject) => {
			API.del("api6b3f7a6e", `/${sourceName}/${id}/${name}s/${related_id}`)
				.then(() => {
					commit("removeRelated", { id: id, name: name, related_id: related_id });
					resolve({
						success: true,
						deleted: id,
					});
				})
				.catch((e) => reject(e));
		});
	},
};
