// eslint-disable-next-line no-unused-vars
import Amplify, { Auth, API } from "aws-amplify";
import Storage from "@aws-amplify/storage";

export default {
	namespaced: true,
	state: {
		user: "",
	},
	getters: {
		isConnected(state) {
			return !!state.user;
		},
		isDarkTheme(state) {
			return state.darktheme;
		},
		isCollapsedSideBar(state) {
			return state.collapsedsidebar;
		},
		IsAdministrator(state) {
			return state.user.IsAdministrator;
		},
	},
	actions: {
		getSession({ commit, getters }) {
			return new Promise((resolve, reject) => {
				Auth.currentAuthenticatedUser()
					.then(() => {
						API.get("api6b3f7a6e", "/MyProfile")
							.then((user) => {
								user.pictureurl = "";
								commit("application/SetIsAwake", null, { root: true });
								commit("SetUser", user);
								commit("loadAvatarUrl");
								resolve();
							})
							.catch((err) => {
								console.log(err);
								if (getters.isConnected) {
									commit("SetUser", "");
								}
								reject();
							});
					})
					.catch(() => {
						commit("SetUser", "");
						reject();
					});
			});
		},
		updateUser({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				API.put("api6b3f7a6e", "/MyProfile", { body: payload })
					.then((res) => {
						commit(`SetUser`, res);
						resolve(state.user);
					})
					.catch((err) => reject(err));
			});
		},
		ChangePassword(args, payload) {
			return new Promise((resolve, reject) => {
				API.post("api6b3f7a6e", "/MyProfile/ChangePassword", {
					body: {
						oldPassword: payload.oldPassword,
						newPassword: payload.newPassword,
						confirmPassword: payload.confirmPassword,
					},
					headers: {
						"Content-Type": "application/json",
					},
				})
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
		UploadAvatar({ commit, state }, File) {
			return new Promise((resolve, reject) => {
				if (File) {
					const filename = state.user.sub + "." + File.name.split(".").pop();
					Storage.put(filename, File, { level: "protected" }).then(() => {
						Auth.updateUserAttributes(state.user, {
							picture: filename,
						})
							.then(function() {
								commit("loadAvatarUrl").then(resolve());
							})
							.catch((err) => reject(err));
					});
				} else {
					resolve();
				}
			});
		},
	},
	mutations: {
		SetUser(state, username) {
			state.user = username;
		},
		SetDarkTheme(state, value) {
			state.darktheme = value;
		},
		SetCollapsed(state, value) {
			state.collapsedsidebar = value;
		},
		async loadAvatarUrl(state) {
			if (state.user.picture) {
				await Storage.get(state.user.picture, { level: "protected", download: false }).then((x) => {
					state.user.pictureurl = x;
				});
			}
		},
	},
};
