<template>
	<markdown-it-vue class="md-body" :content="ParsedMd" :options="options" ref="myMarkdownItVue" />
</template>

<script>
import { Storage } from "aws-amplify";
import MarkdownItVue from "markdown-it-vue";
import MarkdownIt from "markdown-it";
export default {
	components: { MarkdownItVue },
	props: ["content"],
	data() {
		return {
			ParsedMd: "",
			imageMapping: {},
			options: {
				markdownIt: {
					linkify: true,
				},
				linkAttributes: {
					attrs: {
						target: "_blank",
						rel: "noopener",
					},
				},
			},
		};
	},
	methods: {
		async ParseMd() {
			const _this = this;
			let md = new MarkdownIt();
			const parsed = md.parseInline(this.content);
			parsed.forEach((x) => {
				x.children
					.filter((token) => token.type == "image")
					.forEach((element) => {
						element?.attrs.forEach((attr) => {
							if (attr[0] == "src") {
								_this.imageMapping[attr[1]] = attr[1];
							}
						});
					});
			});
			for (var item in this.imageMapping) {
				let tokenized = await Storage.get(this.imageMapping[item]);
				this.imageMapping[item] = tokenized;
			}
			_this.ParsedMd = _this.content;
		},
	},
	async mounted() {
		const _this = this;
		let md = _this.$refs.myMarkdownItVue?.md;

		var defaultRender = md.renderer.rules.image;
		md.renderer.rules.image = function(tokens, idx, options, env, self) {
			var token = tokens[idx];
			token.attrSet("src", _this.imageMapping[token.attrGet("src")]);
			return defaultRender(tokens, idx, options, env, self);
		};
		this.ParseMd();
	},
};
</script>

<style>
div.markdown-body>div {
	text-align: center !important;
}
</style>
