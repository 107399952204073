import state from "../base/state";
import getters from "../base/getters";
import mutations from "../base/mutations";
import actions from "../base/actions";
/**
 * Group store
 */
export default {
	namespaced: true,
	state: {
		...state.data(),

		/**
		 * Store name
		 */
		name: "Users",
	},
	getters,
	actions: {
		...actions,
		addMembership({ getters, dispatch }, { UserId, ProductId }) {
			let user = getters.getById(UserId);
			if (user) {
				if (!user.Memberships) user.Memberships = [];
				if (user.Memberships.indexOf(ProductId) == -1) {
					user.Memberships.push(ProductId);
					dispatch("update", user);
				}
			}
		},
		removeMembership({ getters, dispatch }, { UserId, ProductId }) {
			let user = getters.getById(UserId);
			if (user) {
				user.Memberships = user.Memberships.filter((x) => x != ProductId);
				dispatch("update", user);
			}
		},
	},
	mutations,
};
