import Vue from 'vue'
import Vuex from 'vuex'
import profileStore from './profile'
import applicationStore from './application'
import VuexPersistence from 'vuex-persist'
import news from './modules/News'
import products from './modules/Products'
import users from './modules/Users'
Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({ darktheme: state.application.darktheme, collapsedsidebar : state.application.collapsedsidebar}),
});

export default new Vuex.Store({
  modules: { 
    profile : profileStore,
    application : applicationStore,
    news : news,
    products : products,
    users : users
  },
  plugins: [vuexLocal.plugin]
})
